import deTranslations from "./lang/de"
import enTranslations from "./lang/en"
import pirateTranslations from "./lang/pirate"

interface Translation {
    [key: string]: string;
}

type Language = 'de' | 'en' | 'pirate'

export const translate = (str: string) => {
    const language = localStorage["language"];
    const translations = getTranslations(language);
    return translations[str] ? translations[str] : `!! Translation necessary: ${str} in language ${language} !!`;
}

function getTranslations(language: Language) {
    if (language === 'en') {
        return enTranslations;
    }
    if (language === 'de') {
        return deTranslations;
    }
    return pirateTranslations;
}



