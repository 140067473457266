export default {
    "shiftTime": "Shift time",
    "onlyEmpty": "Only EMPTY shifts",
    "mondayToSunday": "Monday - Sunday",
    "wednesdayToTuesday": "Wednesday - Tuesday",
    "tuesdayToMonday": "Tuesday - Monday",
    "endBeforeStart": "The end time is before the start time.",
    "forgotFilter": "You forgot to activate a section! Please enable at least one section to which this event should be shown.",
    "forgotPreparation": "You forgot the preparation time.",
    "forgotStartTime": "You forgot the starting time",
    "forgotEndTime": "You forgot the end time.",
    "descriptionTooLong": "Your description is too long. The text should contain less than 1500 characters.",
    "wrongShiftWeightFormat": "The format of a shift weight is wrong. The correct format is \"1\", \"0.7\", \"1.4\" or similar.",
    "addCommentHere": "Add comment here",

    'loading': 'Loading...',
    'listOfShiftsDone': 'List of counted shifts done by ',
    'noShiftsInThisPeriod': 'No shifts done in chosen timeframe.',
    'shift': 'Shift',
    'event': 'Event',
    'section': 'Section',
    'date': 'Date',
    'weight': 'Weight',

    "icalfeeds": "Calendarfeeds",
    'remindsBeforeShiftStart': 'Set reminder before the events to',
    'minutes': 'minutes.',
    "noPrivateCalendarWarn": "Please join a shift to get your private Calendar visible!",
    'iCalendarlink': 'link',
    "personalFeed": 'personal feed',
    "internalUsageOnly": "only for internal usage!",
    "publishEventWarning": "If you publish an event it will be visible in the public calendar-feeds.",
    "legend": "Legend",
    'confirmPublishingEvent': 'Are you sure you want to publish this event to calendar feed?',
    'confirmUnpublishingEvent': 'Are you sure you want to remove this event from the calendar feed?',
    'deleteTemplate': 'delete Template',
    'deleteTemplateMessage': 'Are you sure that you want to delete this Template?',
    'dontDeleteLastShift': "You can't remove the last shift, just leave the last shift empty if you don't want any shift for this event.",

    'changeUserStatus': 'Are you sure that you want to change the status of this user?',
    'changeUserStatusHeader': 'Change user status',
    'deleteUserHeader': 'delete user',
    'deleteUser': 'Are you sure you want to delete the user?',
    'replaceShiftTypeConfirmation': 'Do you really want to replace all shifts that use this shift type:',
    'replaceAll': 'replace all',

    'conflictDetected': 'Conflict detected!',
    'conflictAlertLine1': 'Someone else has edited this shift before you. The new values have been updated.',
    'conflictAlertLine2': 'Please check the new information and if you really want to override them, perform your changes again.',

    'offlineTitle': 'You are offline!',
    'offlineMessage': 'Please check your internet connection and try again.',

    'sessionExpired': 'Error: Your Session is expired, please reload the page and login. Than try again.',
    'error': 'Fehler',
};
