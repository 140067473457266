export default {
    "shiftTime": "Dienstzeit",
    "onlyEmpty": "Nur FREIe Dienste",
    "mondayToSunday": "Montag - Sonntag",
    "wednesdayToTuesday": "Mittwoch - Dienstag",
    "tuesdayToMonday": "Dienstag - Montag",
    "endBeforeStart": "Die Endzeit liegt vor der Startzeit!",
    "forgotFilter": "Den Filter vergessen! Bitte setze mindestens eine Sektion, der diese Veranstaltung/Aufgabe gezeigt werden soll.",
    "forgotPreparation": "Die Dienstvorbereitungszeit vergessen!",
    "forgotStartTime": "Die Startzeit vergessen!",
    "forgotEndTime": "Die Endzeit vergessen!",
    "wrongShiftWeightFormat": "Das Format eines Schichtgewichts ist falsch! Das richtige Format ist z.B. \"1\", \"0.7\", \"1.4\" o.Ä.",

    "descriptionTooLong": "Der Beschreibungstext ist zu lange! Der Text sollte weniger als 1500 Zeichen enthalten.",
    "addCommentHere": "Kommentar hier hinzufügen",

    'loading': 'Wird geladen...',
    'listOfShiftsDone': 'Liste aller gezählten Dienste von ',
    'noShiftsInThisPeriod': 'Keine Dienste in dieser Periode.',
    'shift': 'Dienst',
    'event': 'Event',
    'section': 'Sektion',
    'date': 'Datum',
    'weight': 'Wert',

    "icalfeeds": "Kalenderfeeds",
    'remindsBeforeShiftStart': 'Setze eine Erinnerung vor den Events auf',
    'minutes': 'Minuten.',
    "noPrivateCalendarWarn": "Bitte trage dich zuerst für einen Dienst ein um deinen Persönlichen feed angezeigt zu bekommen!",
    'iCalendarlink': 'Link',
    "personalFeed": 'persönlicher Feed',
    "internalUsageOnly": "Nur für den internen Gebrauch!",
    "publishEventWarning": "Wenn Sie die Veranstaltung veröffentlichen wird sie über Öffentlichen Kalenderfeeds sichtbar werden.",
    "legend": "Legende",
    'confirmPublishingEvent': 'Möchtest du dieses Event wirklich zum Kalenderfeed hinzufügen?',
    'confirmUnpublishingEvent': 'Möchtest du dieses Event aus dem Kalenderfeed wirklich entfernen?',
    'deleteTemplate': 'Vorlage löschen',
    'deleteTemplateMessage': 'Bist du dir sicher, dass du diese Vorlage löschen möchtest?',
    'dontDeleteLastShift': "Du kannst den letzten Dienst nicht entfernen. Lass ihn einfach leer, wenn du keine Dienste für diese Veranstaltung brauchst.",

    'changeUserStatus': 'Bist du dir sicher, dass du den Status dieses Nutzers ändern möchtest?',
    'changeUserStatusHeader': 'Benutzerstatus bearbeiten',
    'deleteUserHeader': 'Benutzer Löschen',
    'deleteUser': 'Bist du dir sicher, dass du den Benutzer Löschen möchtest?',
    'replaceShiftTypeConfirmation': 'Möchtest du alle Dienste die den folgenden Diensttyp verwenden wirklich ersetzen?:',
    'replaceAll': 'Alle ersetzen',

    'conflictDetected': 'Konflikt erkannt!',
    'conflictAlertLine1': 'Jemand anderes hat diese Schicht vor dir bearbeitet. Die neuen Werte wurden aktualisiert.',
    'conflictAlertLine2': 'Bitte überprüfe die neuen Informationen und wenn du diese wirklich überschreiben möchtest, führe die Änderungen erneut durch.',

    'offlineTitle': 'Du bist offline!',
    'offlineMessage': 'Bitte überprüfe deine Internetverbindung und versuche es erneut.',

    'sessionExpired': 'Fehler: die Session ist abgelaufen. Bitte aktualisiere die Seite und logge dich ggf. erneut ein.',
    'error': 'Fehler',
};
