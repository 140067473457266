export default {
    "shiftTime": "Deckzeit",
    "onlyEmpty": "Nur FREIe Dienste",
    "mondayToSunday": "Manendach - Sonnendach",
    "wednesdayToTuesday": "Woensdach - Dinxendach",
    "tuesdayToMonday": "Dinxendach - Monedach",
    "endBeforeStart": "Die Endzeit liegt vor der Startzeit!",
    "forgotFilter": "Den Filter vergessen! Bitte setze mindestens eine Sektion, der diese Veranstaltung/Aufgabe gezeigt werden soll.",
    "forgotPreparation": "Die Dienstvorbereitungszeit vergessen!",
    "forgotStartTime": "Die Startzeit vergessen!",
    "forgotEndTime": "Die Endzeit vergessen!",
    "descriptionTooLong": "Der Beschreibungstext ist zu lange! Der Text sollte weniger als 1500 Zeichen enthalten.",
    "wrongShiftWeightFormat": "Das Format eines Schichtgewichts ist falsch! Das richtige Format ist z.B. \"1\", \"0.7\", \"1.4\" o.Ä.",

    "addCommentHere": "Blödsinn ausrufen",

    'loading': 'Der Schiff wird beladen...',
    'listOfShiftsDone': 'Liste aller gezählten Dienste von ',
    'noShiftsInThisPeriod': 'Keine Dienste in dieser Periode.',
    'shift': 'Dienst',
    'event': 'Fahrt',
    'section': 'Schiff',
    'date': 'Datum',
    'weight': 'Wert',

    "icalfeeds": "Kalenderfeeds",
    'remindsBeforeShiftStart': 'Wieviele minuten vor deinem Dienst möchtest du erinnert werden?',
    "noPrivateCalendarWarn": "Bitte trage dich zuerst für einen Dienst ein um deinen Persönlichen feed angezeigt zu bekommen!",
    'iCalendarlink': 'Link',
    "personalFeed": 'persönlicher Feed',
    "internalUsageOnly": "Nur für den Internen gebrauch!",
    "publishEventWarning": "Wenn Sie die Veranstaltung veröffentlichen wird sie über Öffentlichen Kalenderfeeds sichtbar werden.",
    "legend": "Legende",
    'confirmPublishingEvent': 'Möchtest du dieses Event wirklich zum Kalenderfeed hinzufügen?',
    'confirmUnpublishingEvent': 'Möchtest du dieses Event aus dem Kalenderfeed wirklich entfernen?',
    'deleteTemplate': 'Vorlage über bort werfen',
    'deleteTemplateMessage': 'Bist du dir sicher, dass du diese Vorlage löschen möchtest?',
    'dontDeleteLastShift': "You can't remove the last shift, just leave the last shift empty if you don't want any shift for this event.",

    'changeUserStatus': 'Bist du dir sicher, dass du den Status dieses Nutzers ändern möchtest?',
    'changeUserStatusHeader': 'Benutzerstatus bearbeiten',
    'deleteUserHeader': 'Über Bord werfen',
    'deleteUser': 'Sollen wir das Crewmitglied wirklich über Bord werfen?',
    'replaceShiftTypeConfirmation': 'Möchtest du alle Dienste die den folgenden Diensttyp verwenden wirklich ersetzen?:',
    'replaceAll': 'Alle ersetzen',


    'CGW VVK': "Holzbein VVK",
    'Fluten': 'Deck Schrubben',
    'Flutplan': 'Deck Schrubben',
    'Sektbrunch': 'Groggverkostung',
    'Reno': 'Schiff reparieren',
    'Weinabend': 'Rumabend',
    'Kneipenabend': 'Kneipenschlägerei',
    'Fernwehvortrag': 'Seemannsgarn',
    'Geschenkübergabe': 'Priese aufteilen',
    'Walpurgisnacht Vorbereitung': 'Raubzug Vorbereitung',
    'Walpurgisnacht': 'Kaperfahrt',
    'Fete des Profs': 'Fete des Klabautermanns',
    'Wild Wild West': 'Jo Ho Ho Ho',
    'Aufbau Themenabend': 'Papagei füttern',
    'Abbau Themenabend': 'Augenklappe wechseln',
    'Science Slam': 'Black Jack',
    'Frühstück': "Mahlzeit",
    'Nutzung Cafe Veteran': 'Passagierbeförderung: der Alte vom Café',
    '<- Café bleibt zu': 'Café bleibt fest am Dock',
    'Café bleibt zu': 'Café bleibt fest am Dock',
    'Mo-Cafeöffnung': 'Mo-Caféabenteuer',
    'Di-Cafeöffnung': 'Di-Caféabenteuer',
    'Mi-Cafeöffnung': 'Mi-Caféabenteuer',
    'Do-Cafeöffnung': 'Do-Caféabenteuer',
    'Fr-Cafeöffnung': 'Fr-Caféabenteuer',
    'So-Cafeöffnung': 'So-Caféabenteuer',
    'Flyern': 'Rum verteilen',
    'Stefan & Katja Geburtstagsfeier': 'Stefan & Katja: Seemannshochzeit!',
    'CGW Tshirt/Hoodies': 'CGW Kleider',
    'CGW Backgammon/ Kickerturnier /Sonntagsöffnung': 'CGW Schatzsuche und Trinkspiele',
    'CGW Spieleabend': 'CGW "Fünf Finger Fillet"-Abend',
    'Wochentliche ClubBildungsMaßnahme (CBM)': 'Wöchentliche Auspeitschung mit der Neunschwänzigen Katze',

    'conflictDetected': 'Arg, da ist ein Konflikt!',
    'conflictAlertLine1': 'Du hast wohl dem Kapitän nicht zugehört, du Landratte! Ein anderer Matrose ist schon dran!',
    'conflictAlertLine2': 'Schau\'s dir doch genauer an - wolltest du etwa einen Messer in seinen Rücken stecken?!',

    'offlineTitle': 'Arg, du bist offline!',
    'offlineMessage': 'Bitte überprüfe deine Internetverbindung und versuche es erneut.',

    'sessionExpired': 'Fehler: die Session ist abgelaufen. Bitte aktualisiere die Seite und logge dich ggf. erneut ein.',
    'error': 'Fehler',
};
